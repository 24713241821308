import i18n from '@/i18n/index'

export default {
  formData: [{
    span: 16,
    prop: 'name',
    name: 'input',
    label: i18n.t('name'),
    placeholder: i18n.t('name'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'address',
    name: 'input',
    label: i18n.t('address'),
    placeholder: i18n.t('address'),
    Object: {
    }
  }]
}
