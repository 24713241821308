<template>
  <div>
      <alter-form labelWidth="140px" :watchImageVisible="false" :modifyData="modifyData" @formSubmit="formSubmit" :formData="formData"></alter-form>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
import loading from '@/utils/loading.json'
import jsonData from '../jsonData'
// import constant from '@/utils/constant.js'
// import Utils from '@/utils/Utils.js'
import apis from '@/services/index'

export default {
  name: 'general',
  components: {
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      if (this.$route.params.id) {
        const data = await apis.AssetApi.getLocationPreview(this.$route.params.id)
        this.modifyData = data.data.location
      }
      this.$store.state.app.loading.close()
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        location: obj
      }
      // console.log(params)
      // Object.keys(params.store).forEach(res => {
      //   if (typeof params.store[res] === 'boolean') {
      //     if (params.store[res] === true) params.store[res] = '1'
      //     if (params.store[res] === false) params.store[res] = '0'
      //   }
      // })
      // if (params.store.operation_hours instanceof Array || !Utils.isNull(params.store.operation_hours)) {
      //   if (params.store.operation_hours[0] === '') {
      //     params.store.operation_hours = ''
      //   } else {
      //     params.store.operation_hours = params.store.operation_hours.join('-')
      //   }
      // }
      let api = null
      const arr = []
      if (this.$route.params.id) {
        api = apis.AssetApi.getLocationEdit
        arr.push(this.$route.params.id)
      } else {
        api = apis.AssetApi.getLocationAdd
      }
      arr.push(params)
      api(...arr).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess'),
            duration: 1000,
            onClose: () => { this.$router.go(-1) }
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scope>
</style>
