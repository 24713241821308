<template>
<div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general"></el-tab-pane>
      </el-tabs>
      <keep-alive>
        <component :is="name"></component>
      </keep-alive>
    </el-card>
  </div>
</template>
<script>
import generalVue from './component/general.vue'
export default {
  components: { generalVue },
  data () {
    return {
      name: 'generalVue',
      activeName: 'general'
    }
  },
  created () {
  },
  methods: {
    handleClick () {
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
